.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

$container-max-widths: (
        sm: 540px,
        lg: 720px
);

$body-bg: #ced4da;
$grid-gutter-width: 20px;

$blue:       #007bff;
$indigo:     #6610f2;
$purple:     #6f42c1;
$pink:       #e83e8c;
$red:        #b9174f;
$orange:     #fd7e14;
$yellow:     #ffc107;
$green:      #28a745;
$teal:       #20c997;
$cyan:       #17a2b8;
$gray:       #c2bcb4;
$lightgray:  #eae3d9;

@import '~bootstrap/scss/bootstrap';

.songbook.zoom-level-1  { font-size: 0.8227rem;}
.songbook.zoom-level-2  { font-size: 0.8638rem;}
.songbook.zoom-level-3  { font-size: 0.9070rem;}
.songbook.zoom-level-4  { font-size: 0.9523rem;}
.songbook.zoom-level-5  { font-size: 1.0000rem;}
.songbook.zoom-level-6  { font-size: 1.0499rem;}
.songbook.zoom-level-7  { font-size: 1.1024rem;}
.songbook.zoom-level-8  { font-size: 1.1576rem;}
.songbook.zoom-level-9  { font-size: 1.2155rem;}
.songbook.zoom-level-10 { font-size: 1.2762rem;}
.songbook.zoom-level-11 { font-size: 1.3400rem;}
.songbook.zoom-level-12 { font-size: 1.4070rem;}
.songbook.zoom-level-13 { font-size: 1.4774rem;}
.songbook.zoom-level-14 { font-size: 1.5513rem;}
.songbook.zoom-level-15 { font-size: 1.6288rem;}
.songbook.zoom-level-16 { font-size: 1.7103rem;}
.songbook.zoom-level-17 { font-size: 1.7958rem;}
.songbook.zoom-level-18 { font-size: 1.8856rem;}
.songbook.zoom-level-19 { font-size: 1.9799rem;}
.songbook.zoom-level-20 { font-size: 2.0789rem;}

@include media-breakpoint-up(sm) {
  .songbook.zoom-level-1  { font-size: 1.0000rem;}
  .songbook.zoom-level-2  { font-size: 1.0499rem;}
  .songbook.zoom-level-3  { font-size: 1.1024rem;}
  .songbook.zoom-level-4  { font-size: 1.1576rem;}
  .songbook.zoom-level-5  { font-size: 1.2155rem;}
  .songbook.zoom-level-6  { font-size: 1.2762rem;}
  .songbook.zoom-level-7  { font-size: 1.3400rem;}
  .songbook.zoom-level-8  { font-size: 1.4070rem;}
  .songbook.zoom-level-9  { font-size: 1.4774rem;}
  .songbook.zoom-level-10 { font-size: 1.5513rem;}
  .songbook.zoom-level-11 { font-size: 1.6288rem;}
  .songbook.zoom-level-12 { font-size: 1.7103rem;}
  .songbook.zoom-level-13 { font-size: 1.7958rem;}
  .songbook.zoom-level-14 { font-size: 1.8856rem;}
  .songbook.zoom-level-15 { font-size: 1.9799rem;}
  .songbook.zoom-level-16 { font-size: 2.0789rem;}
  .songbook.zoom-level-17 { font-size: 2.1828rem;}
  .songbook.zoom-level-18 { font-size: 2.2920rem;}
  .songbook.zoom-level-19 { font-size: 2.4066rem;}
  .songbook.zoom-level-20 { font-size: 2.5269rem;}
}

.songbook {
  touch-action: pan-y;
  padding-bottom: 60px;
}

.songtitle {
  font-size: 1.2em;
  font-weight: bold;
}

.sb-chord {
  color: $red;
  font-weight: 500;
}

.sb-menu-buttons-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  display: flex;
  flex-direction: row;

  .sb-menu-button {
    &.sb-menu-button-cyan {
      @include button-variant($cyan, $cyan);
    }

    &.sb-menu-button-gray {
      @include button-variant($gray, $gray);
    }

    &.sb-menu-button-red {
      @include button-variant($red, $red);
    }
  
    width: 50px;
    height: 50px;
    margin-left: 5px;
  
    .sb-menu-button-icon {
      width: 50px;
      height: 50px;
      font-size: 1.3em;
    }
  }
}

.sb-menu-songtitle {
  font-size: 1.05rem;
  line-height: 1.8;
}

.sb-chosen-song {
  font-weight: bold;
  text-decoration: underline;
}

.sb-starred-song {
  font-weight: bold;
  color: $cyan;
}

.sb-star-info {
  color: $gray;
  font-weight: normal;

  &.sb-lyrics {
    font-size: 1.00rem;
    text-align: center;
  }
}

.sb-star {

  &.sb-star-plain {
    color: $lightgray;
  }

  &.sb-star-starred {
    color: $yellow;
  }

  &.sb-star-selected {
    color: $red;
  }

}
